import { partytownSnippet } from '@builder.io/partytown/integration'

import type { PublicRuntimeConfig } from 'nuxt/schema'
import { forwardDatadog, injectDatadog } from '~/lib/external-tracking/datadog'
import { forwardSegment, injectSegment, injectSegmentPlugin } from '~/lib/external-tracking/segment'
import { forwardGoogleGtag, injectGtag, injectGtagBase } from '~/lib/external-tracking/googleGtag'
import { forwardClarity, injectClarity } from '~/lib/external-tracking/clarity'

declare global {
  interface Window {
    injected: boolean
  }
}

/**
 * This composable injects Partytown scripts into the head of the page.
 */
export default function usePartytown() {
  const runtimeConfig = useRuntimeConfig()
  const { $experiment: experiment, $cookies: cookies } = useNuxtApp()
  const route = useRoute()
  const { user } = useAuthentication()

  const publicConfig = runtimeConfig.public

  const partytownLogging = publicConfig.partytown.logging
  const partytownLoggingVerbose = publicConfig.partytown.loggingVerbose

  const partytownForwards: string[] = []
  const partytownReverseProxy: string[] = []

  if (publicConfig.partytown.datadogEnabled) {
    partytownForwards.push(...forwardDatadog)
  }

  if (publicConfig.partytown.segmentEnabled && publicConfig.segment.enabled) {
    partytownForwards.push(...forwardSegment)
  }

  if (publicConfig.partytown.gtagEnabled && publicConfig.gtag.enabled) {
    partytownForwards.push(...forwardGoogleGtag)
  }

  partytownForwards.push(...forwardClarity)

  partytownReverseProxy.push('connect.facebook.net')
  partytownReverseProxy.push('bat.bing.com')
  partytownReverseProxy.push('googletagmanager.com')
  partytownReverseProxy.push('https://www.clarity.ms')

  const debug = partytownLogging
    ? `debug: true,
      logCalls: ${partytownLoggingVerbose},
      logGetters: ${partytownLoggingVerbose},
      logSetters: ${partytownLoggingVerbose},
      logImageRequests: ${partytownLoggingVerbose},
      logMainAccess: ${partytownLoggingVerbose},
      logSendBeaconRequests: ${partytownLoggingVerbose},
      logStackTraces: ${partytownLoggingVerbose},
      logScriptExecution: ${partytownLoggingVerbose},`
    : `debug: false,`

  const injectPartytownConfig = {
    key: 'partytown-config',
    children: `
      partytown = {
        globalFns: ['UET'],
        ${debug}
        forward: ${JSON.stringify(partytownForwards)},
        resolveUrl: (url) => {
          if (${JSON.stringify(partytownReverseProxy)}.includes(url.hostname)) {
            const proxyUrl = new URL('${publicConfig.reverseProxyUrl}')
            proxyUrl.searchParams.append('url', url.href)
            return proxyUrl
          }
          return url
        }
      };
    `,
  }

  const injectPartyTown = {
    key: 'partytown',
    children: partytownSnippet,
  }

  const headers = useRequestHeaders()
  const userAgent = headers['user-agent']

  const { isPotentiallyABot } = useBotScore()

  useHead({
    script: [
      injectPartytownConfig,
      injectPartyTown,
      ...injectPartytownScripts({
        $config: publicConfig,
        $experiment: experiment,
        $user: user,
        $cookies: cookies,
        $query: route.query,
        $userAgent: userAgent,
        isPotentiallyABot,
      }),
    ],
  })

  if (import.meta.dev && import.meta.server) {
    const isPartytownEnabled = publicConfig.partytown.enabled

    console.log(`🎉 Partytown is ${isPartytownEnabled ? 'enabled' : 'disabled'}`)
  }

  // Generates the head scripts to inject.
  function injectPartytownScripts({ $config, $user, $query, $cookies, $experiment, $userAgent, isPotentiallyABot }: { $config: PublicRuntimeConfig, $cookies: typeof cookies, $user: typeof user, $query: typeof route.query, $experiment: typeof experiment, $userAgent: string, isPotentiallyABot: boolean }) {
    /**
     * Ugly hack in order to prevent the scripts from being injected twice on the client.
     * Perhaps this can removed once scripts are properly handled.
     */
    if (import.meta.client && window?.injected) {
      return []
    }
    // Filter undefined/disabled scripts
    return [
      injectDatadog({ $partytown: $config.partytown, $config: $config.datadog.common, $experiment, $cookies, $userAgent, isPotentiallyABot }),
      injectSegment({ $partytown: $config.partytown, $config: $config.segment, $cookies }),
      injectSegmentPlugin({ $partytown: $config.partytown, $config: $config.segment }),
      injectGtagBase({
        $partytown: $config.partytown,
        $config: $config.gtag,
        $googleAnalyticsConfig: $config.googleAnalytics,
      }),
      injectGtag({
        $partytown: $config.partytown,
        $config: $config.gtag,
        $googleAnalyticsConfig: $config.googleAnalytics,
        $googleAdsConfig: $config.googleAds,
        $user,
        $query,
      }),
      injectClarity({ $partytown: $config.partytown, $experiment, $config: $config.microsoftClarity }),
      {
        children: `injected = true;`,
      },
    ].flatMap((item) => item || [])
  }
}
